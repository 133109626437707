
:root{
  --main-color: #ECB242;
  --shadow:0 1px 2px rgba(0, 0, 0, 0.3);
  --backgroundColor:#f7f7f3;
  --mainFontColor:#191919;
  --radialGradientColor:#00bbff40;
}

#map{
  position: fixed;
  width: 100vw;
  height:100%;
  top: 0;
  right: 0;
  /* z-index: -1; */
  opacity: 0;
  transition:all 500ms;
}

*{
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color: inherit;
}

h2,h3, h4{
  margin: 0;
}

p{
  margin: 0;
}

.link {
  fill: none;
  stroke: #000;
  stroke-opacity: .2;
}

.link:hover {
  stroke-opacity: .5;
}

body{
  background-color: var(--backgroundColor);
  /* background: rgb(27, 27, 27); */
  height: auto;
  width: 100vw;
  margin: 0;
  font-family: 'Nunito', sans-serif;
  overflow: hidden;
  transition: all 200ms; 
  position: relative;
}

html{
  scroll-behavior: smooth;
}

.navbar{
  height: 5em;
  width: 100%;
  display: flex;
  align-items: center;
  /* padding: 4em 3em; */
  justify-content: space-between;
  position: fixed;
  /* background:linear-gradient(270deg,rgb(201, 34, 201),transparent); */
  z-index: 5;
  /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.459); */
  /* border-radius: 0 0 50px 50px; */
  /* border-image: linear-gradient(90deg,transparent 30%, var(--main-color)) 1;
  border-style: solid;
  border-width: 0 0 2px 0; */
  box-shadow: 0 1px 2px rgba(0,0,0,0.3);
  background: var(--backgroundColor);
  backdrop-filter: brightness(1.2);
}


.navbar-logo{
  /* font-family: 'Galada', cursive; */
  /* color: white; */
  /* font-size: 2em; */
  /* text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.315); */
  padding-left: 3em;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;
}

.navbar-logo >p{

  margin: 0;
  font-size: 2em;
}

.navbar-logo-svg{

  width: 3em;
  height: 3em;
  margin-right: 1em;

}

.navbar-logo-svg >svg{
  width: 100%;
  height: 100%;

}

/* .navbar-logo::before{
  content: '\2B22';
  font-size: 2em;
  position: absolute;
  right: 0;
  /* background-color: #000000; */
  /* width: 2em;
  height: 2em;
  box-shadow: 0 0 5px black;
} */ 

.navbar-panel{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5em;
  text-align: center;
  justify-content: flex-end;
}

.navbar-panel-signup{
  background-color: var(--main-color);
  padding: 1em 2em;
  border-radius: 10px;
  color: var(--mainFontColor);
  font-weight: 700;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  transition: all 200ms;
  margin: 0 2em;
}

.navbar-panel-signup:hover{
  cursor: pointer;
  transform: scale(1.05);
  filter: brightness(0.9);
}


.navbar-button{
  font-size: 1em;
  height: 100%;
  width: 100%;
  color: var(--mainFontColor);
  opacity: 1;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0 4em;
  transition: all 200ms;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
  justify-content: center;
}

.navbar-button:hover{
  opacity: 1;
  cursor: pointer;
  border-bottom: 2px solid var(--main-color);
  backdrop-filter: brightness(0.9);
}

.frontpage{
  padding-top: 5em;
  width: 100vw;
  height: 100dvh;
  display: flex;
  flex-direction: row;
  /* padding-top: 6em; */
  position: relative;
  /* align-items: center; */
  /* justify-content: center; */
  overflow: hidden;
}

.frontPage-burgerMenu{
  position: relative;
  right: 0;
  padding: 1.5em;
  margin: 0.2em;
  display: none;
  flex-direction: column;
  z-index: 1;
  align-items: flex-end;
  /* background-color: var(--backgroundColor); */
  border-radius: 5px;
  /* box-shadow: var(--shadow); */
}

.frontPage-burgerMenu:hover{
  cursor: pointer;
  filter: brightness(0.9);
}

.frontPage-burgerMenu-icon{
  font-size: 2em;
}

.frontPage-burgerMenu-panel{
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0.2em;
}

.frontPage-welcomePanel{
  position: absolute;
  padding: 2em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  max-width: 50%;
  z-index: 1;
  transition: all 500ms;
  min-width: min-content;
}

.frontPage-welcomePanel-intro{
  padding: 2em;
  border-radius: 5px;
  box-shadow: var(--shadow);
  background-color: var(--backgroundColor);
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  transition: all 500ms;
  position: relative;
}

.frontPage-welcomePanel-intro > p{
  font-size: 1.1em;
  color: var(--mainFontColor);
}

.frontPage-welcomePanel-intro-buttons{
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding-top: 1em;
}

.frontPage-welcomePanel-intro-buttons >button{
  padding: 0.5em 1em;
  border-radius: 5px;
  color: var(--mainFontColor);
  font-weight: 700;
  box-shadow: var(--shadow);
  border: none;
  transition: all 200ms;
  font-family: inherit;
}

.frontPage-welcomePanel-intro-buttons >button:hover{
  cursor: pointer;
  filter: brightness(0.9);
  transform: scale(1.05);
}

.frontPage-demo-buttons{
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 1em;
}

.frontPage-demo{
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: baseline;
}

.frontPage-demo-progressDots{
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 1em;
}

.frontPage-demo-progressDot{
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: linear-gradient(180deg,rgba(255, 255, 255, 0.568),transparent);
  transition: all 200ms;
  box-shadow: inset var(--shadow);
}

.frontPage-demo-entry{
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.frontPage-demo-requirementLine{
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
}

.frontPage-demo-next{
  appearance: none;
  border: none;
  background-color: var(--main-color);
  padding: 0.5em 1em;
  border-radius: 5px;
  box-shadow: var(--shadow);
  font-family: inherit;
  font-weight: 700;
  transition: all 200ms;
}

.frontPage-demo-back{
  appearance: none;
  border: solid 1px gray;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-family: inherit;
  font-weight: 700;
  transition: all 200ms;
}

.frontPage-demo-close:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.frontPage-demo-next:hover, .frontPage-demo-back:hover{
  cursor: pointer;
  filter: brightness(0.9);
  transform: scale(1.05);
}

.frontPage-demo-loadBar{
  width: 0;
  height: 1em;
  background-color: var(--main-color);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: all 3000ms;
}

.frontPage-demo-map-legend{
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%;
  top:5em;
  padding: 1em;
  border-radius: 0 5px 5px 0;
  gap: 0.5em;
  background: linear-gradient(90deg,#c8ceda,#969fb1);
}

.frontPage-demo-map-legend-entry{
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
  padding: 0.2em;
  border-radius: 5px;
  background-color: var(--backgroundColor);
  box-shadow: var(--shadow);
  transition: all 250ms;
}

.frontPage-demo-map-legend-entry:hover{
  cursor: pointer;
  filter: brightness(0.9);
}

.frontPage-demo-map-legend-entry-color{
  width: 1em;
  height: 1em;
  border-radius: 5px;
  box-shadow: inset var(--shadow);
  background: linear-gradient(180deg,rgba(255, 255, 255, 0.568),transparent);
}

.finishedLoading{

  transition: all 500ms;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finishedLoading::after{

  position: absolute;
  content: '\2713';
  font-size: 1.5em;
  color: white;
  font-weight: 700;
  animation: finishedLoading 1000ms forwards 500ms;

}

.midpage{
  width: 100vw;
  height: 100dvh;
  position: relative;
  padding:5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.midpage-panel >img{
  width: 100%;
  max-width: 1024px;
  position: relative;
  top: 0;
}

.midpage-panel-section{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  min-height: fit-content;
  height: 50%;
  gap: 1em;
}

.midpage-panel{
  min-width: min-content;
  width: 30%;
  height: 100%;
  height: fit-content;
  border-radius: 10px;
  box-shadow: inset 0 0 3px white, 0 1px 15px rgba(0, 0, 0, 0.415);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  backdrop-filter: blur(10px) brightness(1.2);
}

.midpage-panel:nth-of-type(2){
  margin-bottom: auto;
}

.midpage-panel >p{
  padding: 1em;
  font-weight: 700;
}

/* 
.midpage::before{
  position: absolute;
  content: "";
  width: 100%;
  height: 5vw;
  background-color: var(--backgroundColor);
  bottom: 0;
  border-radius: 100% 0 0 0;
  transform: rotatey(0deg);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.445);
} */

@keyframes slide{
  0% {
      top:-10%;
      left: -10%;
      opacity: 0;
  }

  50%{
      opacity: 0.9;
  }

  100%{
      top: 100%;
      left: 110%;
      opacity: 0;
  }
}

@keyframes slide1{
  0% {
      top:0;
      left: -30%;
      opacity: 0;
  }

  50%{
      opacity: 0.9;
  }

  100%{
      top: 100%;
      left: 70%;
      opacity: 0;
  }
}

@keyframes slide2{
  0% {
      top:0;
      left: 30%;
      opacity: 0;
  }

  50%{
      opacity: 0.9;
  }

  100%{
      top: 70%;
      left: 130%;
      opacity: 0;
  }
}

.login-overlay{
    position: fixed;
    width: 100vw;
    height: 100dvh;
    top:0;
    /* background-color: rgba(128, 128, 128, 0.445); */
    justify-content: center;
    align-items: center;
    /* padding-top: 4em ; */
    opacity: 0;
    display: flex;
    visibility: hidden;
    transition: all 500ms;
    backdrop-filter: blur(5px) brightness(0.5);
    z-index: 5;
}

.overlay-active{
    opacity: 1;
    visibility: visible;
}

.welcome{
  position: absolute;
  top: 30dvh;
  left:10vw;
  font-size: 4em;
  width: 34vw;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 0.5em;
  border-radius: 10px;
  box-shadow: 5px 5px 7px 1px rgba(51, 50, 50, 0.514);
  color: antiquewhite;
  font-weight: 100;
}

.login{
  height: 100dvh;
  position: absolute;
  background: rgba(255, 255, 255, 0);
  width: 50vw;
min-width: 800px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  flex-direction: column;
  transition: all 500ms;
}

.terms{

  font-size: 0.8em;

}

.container >h1 {
font-weight: bold;
margin: 0;
}

.container > h2 {
text-align: center;
}

.container > p {
font-size: 14px;
font-weight: 100;
line-height: 20px;
letter-spacing: 0.5px;
margin: 20px 0 30px;
}

span {
font-size: 12px;
}

a {
color: #333;
text-decoration: none;
}


form {
/* background-color: #FFFFFF; */
display: flex;
align-items: center;
/* justify-content: center; */
flex-direction: column;
/* padding: 0 50px; */
height: 100%;
text-align: center;
}

input {
background-color: #eee;
border: solid 1px gray;
padding: 12px 15px;
margin: 8px 0;
width: 100%;
  border-radius: 5px;
font-family: inherit;
}

.container {
border-radius: 5px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.456);
position: absolute;
width: 30%;
min-height: min-content;
  height: fit-content;
  transition: all 1000ms;
  transition-timing-function:cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 2em;
  /* border-image: linear-gradient(180deg,transparent,var(--main-color), transparent) 1; */
  /* border-style: solid; */
  /* border-width: 3px; */
  /* background: radial-gradient(farthest-corner at 0 0, white, transparent); */
  /* background: linear-gradient(180deg,transparent, black, transparent); */
  background-color: #e5e5dd;
  display: flex;
  justify-content: center;
}

.container-exit{
  position: absolute;
  width: 1em;
  height: 1em;
  right: 0.5em;
  top: 0.5em;
  /* color: rgba(255, 255, 255, 0.724); */
  /* text-shadow: 0px 0px 3px white; */
  font-size: 1em;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms;
}

.container-exit:hover{
  cursor: pointer;
  transform: scale(1.05);
  color: black;
}

.form-container {
/* position: absolute; */
/* top: 0; */
width: fit-content;
  height: 100%;
transition: all 0.6s ease-in-out;
  margin-bottom: 5em;
  padding: 1em;
  color: var(--mainFontColor);
}


.sign-up-container {
left: 0;
width: 50%;
opacity: 0;
z-index: 1;
}

.error::after{
content: attr(result);
width: fit-content;
height: fit-content;
position: absolute;
background: #B33F40;
border-radius: 5px;
top: -3em;
right: 1em;
padding: 0.5em;
box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.486);
transition: all 500ms;
color: white;
}

.pass::after{
content: attr(result);
border-radius: 5px;
width: fit-content;
height: fit-content;
position: absolute;
background: #48A14D;
top: -3em;
right: 1em;
padding: 0.5em;
box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.486);
transition: all 500ms;
color: white;
}

.container.right-panel-active .sign-up-container {
transform: translateX(100%);
opacity: 1;
z-index: 5;
animation: show 0.6s;
}

@keyframes show {
0%, 49.99% {
  opacity: 0;
  z-index: 1;
}

50%, 100% {
  opacity: 1;
  z-index: 5;
}
}

.overlay-container {
position: absolute;
top: 0;
left: 50%;
width: 50%;
height: 100%;
overflow: hidden;
transition: transform 0.6s ease-in-out;
z-index: 100;
  background-color: var(--main-color);
}

.container.right-panel-active .overlay-container{
transform: translateX(-100%);
}

.overlay {
background-repeat: no-repeat;
background-size: cover;
background-position: 0 0;
color: #000000;
position: relative;
left: -100%;
height: 100%;
width: 200%;
  transform: translateX(0);
transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
position: absolute;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
  padding: 0 40px;
top: 0;
height: 100%;
width: 50%;
transform: translateX(0);
transition: transform 0.6s ease-in-out;
}

.overlay-left {
transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
transform: translateX(0);
}

.overlay-right {
right: 0;
transform: translateX(0);
}

.container.right-panel-active .overlay-right {
transform: translateX(20%);
}

.social-container {
/* margin: 20px 0; */
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  width: 100%;
}

.social-container a {
/* border: 1px solid #00000055; */
border-radius: 5px;
display: inline-flex;
/* justify-content: center; */
align-items: center;
/* margin: 0 5px; */
height: fit-content;
width: 100%;
  min-width: fit-content;
  transition: all 200ms;
  flex-direction: row-reverse;
  gap: 1em;
  justify-content: center;
  padding: 0.5em;
  font-size: 1em;
  backdrop-filter: brightness(1.2);
  /* background-color: white; */
  /* box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.333); */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.social:hover{
  /* background-color: black;
  color: white; */
  filter: brightness(0.9);
}

footer {
  background-color: #222;
  color: #fff;
  font-size: 14px;
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 999;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.3);
}

footer p {
  margin: 10px 0;
}

footer a {
  text-decoration: none;
}

footer{
  height: fit-content;
  position: absolute;
  bottom: 0;
  display: flex;
  font-size: 1em;
  background-color: var(--main-color);
  color: black;
  font-weight: 600;
  text-align:center;
  justify-content: space-around;
  padding: 1em;
}

.footerpage{
  width: 100vw;
  height: 100dvh;
}

.footer-home{
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.footer-legal{
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.footer-legal > a {
  display: block;
  margin: 0;
}

.footer-contact{
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.footer-social{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 1em;
}

.footer-title{
  font-size: 1em;
  margin-bottom: 0.5em;
}

.footer-copyright{
  position: absolute;
  bottom: 0;
  white-space: nowrap;
  text-align: center;
  color: rgba(0, 0, 0, 0.514);
}

#particles {
  background: #000000;
}
#hexagonGrid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#hexagonGrid .row {
  display: inline-flex;
  margin-top: -32px;
  margin-left: -50px;
}
#hexagonGrid .row:nth-child(even) {
  margin-left: 2px;
}
#hexagonGrid .row .hexagon {
  position: relative;
  width: 100px;
  height: 110px;
  margin: 4px 2px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
#hexagonGrid .row .hexagon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.95;
  transition: 1s;
}
#hexagonGrid .row .hexagon::after {
  content: '';
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  background: #141414;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
#hexagonGrid .row .hexagon:hover::before {
  background: #8CFD0B;
  opacity: 1;
  transition: 0s;
}
#hexagonGrid .row .hexagon:hover::after {
  background: #000000;
}

canvas{
  position: absolute;
  top: 0;
}

@keyframes handWave {

  0%{
      transform: rotate(0deg);
  }

  25%{
      transform: rotate(40deg);
  }

  50%{
      transform: rotate(0deg);
  }

  75%{
      transform: rotate(40deg);
  }

  100%{
      transform: rotate(0deg);
  }


}

@keyframes click {

  0%{
      filter: brightness(1);
      transform: scale(1);
  }

  50%{
      filter: brightness(0.5);
      transform: scale(0.9);
  }

  100%{
      filter: brightness(1);
      transform: scale(1);
  }

}

@keyframes loading {
  
  0%{
      transform: rotate(0deg);
  }

  100%{
      transform: rotate(360deg);
  }


}

@keyframes finishedLoading {
  
  0%{
      transform: scale(0);
      opacity: 0;
  }

  100%{
      opacity: 1;
      transform: scale(1);
  }

}

/* Honeycomb loader*/

@-webkit-keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.honeycomb {
  height: 24px;
  position: absolute;
  width: 24px;
  margin-top: 50dvh;
  margin-left: 50vw;
  transition: all 500ms ease-in-out;
}

.honeycomb div {
  -webkit-animation: honeycomb 2.1s infinite backwards;
  animation: honeycomb 2.1s infinite backwards;
  background: #f3f3f3;
  height: 12px;
  margin-top: 6px;
  position: absolute;
  width: 24px;
}

.honeycomb div:after, .honeycomb div:before {
  content: '';
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
}

.honeycomb div:after {
  top: -6px;
  border-bottom: 6px solid #f3f3f3;
}

.honeycomb div:before {
  bottom: -6px;
  border-top: 6px solid #f3f3f3;
}

.honeycomb div:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  left: -28px;
  top: 0;
}

.honeycomb div:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  left: -14px;
  top: 22px;
}

.honeycomb div:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  left: 14px;
  top: 22px;
}

.honeycomb div:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  left: 28px;
  top: 0;
}

.honeycomb div:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  left: 14px;
  top: -22px;
}

.honeycomb div:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  left: -14px;
  top: -22px;
}

.honeycomb div:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 600px) {
  body{
    font-size: 0.8rem;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {
  body{
    font-size: 0.8rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  body{
    font-size: 0.9rem;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  body{
    font-size: 0.9rem;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  body{
    font-size: 0.9rem;
  }
}

@media screen and (max-aspect-ratio:3/3){

  /* body{
    overflow: auto;
  } */

  .frontPage-welcomePanel{
    max-width: 100%;
    height: fit-content;
    bottom: 0;
    top: unset;
  }

  .midpage-panel-section{
    flex-direction: column;
    height: 100%;
    gap: 1em;
  }

  .midpage-panel{
    width: fit-content;
  }

  .navbar{
    gap: 1em;
  }

  .navbar-button{
    display: none;
  }

  .navbar-logo{
    padding-left: 1.5em;
  }

  .frontPage-demo-map-legend{
    left: 0;
    bottom: 100%;
    top: unset;
    right: unset;
    border-radius: 5px 5px 0 0;
  }

  .container{
    width: 70%;
    min-width: min-content;
  }

  .frontPage-burgerMenu{
    display: flex;
  }

  .midpage-panel:nth-of-type(2){
    margin-bottom: 0;
  }

  .navbar-logo >p{
    font-size: 1.5em;
  }

  .navbar-panel{
    position: absolute;
    top: 0;
    right: 0;
    backdrop-filter: blur(10px);
  }

  .navbar-button{
    padding: 0 1em;
    color: white;
  }

  .navbar-panel-signup{
    margin: 0;
  }

  .midpage{
    padding: 0;
  }

  .midpage-panel{
    color: white;
    backdrop-filter: brightness(0.5) blur(10px);
  }

}
